<template>
    <div :class="{'footer-main':true, en: lang=='en'}">
        <Block class="content">
            <div class="block1">
                <div class="content-block">
                    <div class="title">{{$t('footer.title1')}}</div>
                    <div class="content" v-for="(item, i) in $t('footer.content1')">{{item}}</div>
                </div>
            </div>
            <div class="block2">
                <div class="content-block">
                    <div class="title">{{$t('footer.title2')}}</div>
                    <div class="content" v-for="(item, i) in $t('footer.content2')">{{item}}</div>
                </div>
                <div class="content-block">
                    <div class="title">{{$t('footer.title3')}}</div>
                    <div class="content" v-for="(item, i) in $t('footer.content3')">{{item}}</div>
                </div>
            </div>

            <div class="block3">
                <div class="content-block">
                    <div class="title">{{$t('footer.title5')}}</div>
                    <div class="content" v-for="(item, i) in $t('footer.content5')">{{item}}</div>
                </div>
            </div>
            <div class="block4">
                <div class="content-block">
                    <div class="title">{{$t('footer.title7')}}</div>
                    <div class="content pointer" @click="goUrl('http://www.citf.com.cn')">www.citf.com.cn</div>
                    <div class="content pointer" @click="goUrl('https://beian.miit.gov.cn/')">粤ICP备19137685号</div>
                </div>
                <div class="content-block">
                    <div class="title">{{$t('footer.title8')}}</div>
                    <div class="content pointer" v-for="(item, i) in $t('footer.content8')" @click="goUrl(item.url)">{{item.name}}</div>
                </div>
            </div>
        </Block>
    </div>
</template>

<script>
import Block from './layout2-block.vue';
import {getLanguage} from "@/lang";
export default {
    name: 'layout2-footer',
    components: {
        Block,
    },
    data() {
        return {
            lang: getLanguage(),
        }
    },
    computed: {

    },
    methods:{
        goUrl(url){
            window.open(url);
        }
    },
    watch: {

    }
}
</script>
<style lang="less" scoped>
.footer-main {
    display: flex;
    justify-content: center;
    min-width: 100%;
    height: 350px;
    background: url(~@/assets/img/footer-bg.png) no-repeat center top #33579C;
}
.content {
    display: flex;
}
.block1 {
    width: 300px;
    flex-shrink: 0;
    padding-right: 18px;
    box-sizing: border-box;
}
.block2 {
    width: 300px;
    flex-shrink: 0;
    padding-right: 18px;
    box-sizing: border-box;
}
.block3 {
    width: 300px;
    flex-shrink: 0;
    padding-right: 18px;
    box-sizing: border-box;
}
.block4 {
    width: 300px;
    flex-shrink: 0;
}
.pointer{
    cursor: pointer;
}
.content-block {
    margin-top: 22px;
    &:first-child {
        margin-top: 22px;
    }
    .title {
        color: #ffffff;
        // font-size: 20px;
        font-weight: bold;
        // height: 20px;
        line-height: 24px;
        font-size: 18px;
        margin-bottom: 8px;
    }
    .content {
        // font-size: 14px;
        font-size: 12px;
        font-weight: 400;
        // line-height: 16px;
        // margin-top: 10px;
        line-height: 24px;
        color: #ffffff;
        // white-space: nowrap;
        &:first-child{
            margin-top: 0;
        }
        &:hover{
            text-decoration: underline;
        }
    }
}

.en{
    .block2{
      width: 286px;
    }
    .block3{
      width: 314px;
    }
    .block4 {
      width: 300px;
    }
    .content-block {
      margin-top: 20px;
      &:first-child {
          margin-top: 12px;
      }
      .content {
        line-height: 20px;
      }
  }
}
</style>
