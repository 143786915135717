<template>
    <div class="main">
        <slot></slot>
        <div class="fix">
            <div class="fix-item" v-if="back && canBack()" @click="$router.go(-1)">
                <img :src="require('@/assets/img/back.png')" />
            </div>
        </div>
        <el-backtop :bottom="40" :visibility-height="60">
            <img :src="topIcon" />
        </el-backtop>
        <qr-code v-if="qrcode"></qr-code>
        <sign-up-qr-code></sign-up-qr-code>
    </div>
</template>

<script>
import topIcon from "@/assets/img/top.png";
import QrCode from "./layout2-qrcode.vue";
import SignUpQrCode from "./layout2-sign-up-qrcode.vue";
export default {
    name: 'layout2-main',
    props:{
        //是否显示二维码
        qrcode: false,
        //是否显示后退按钮
        back: false,
    },
    components: {
        QrCode,
        SignUpQrCode,
    },
    data() {
        return {
            topIcon,
        }
    },
    methods:{
        canBack(){
            return window.history.length > 1;
        }
    },
    computed: {

    },
    watch: {

    }
}
</script>
<style lang="less" scoped>
.main {
    width: 100%;
    min-width: 1280px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /deep/ .el-backtop{
        width: 50px;
        height: 50px;
        background: #3053A3;
        border-radius: 4px;
        z-index: 2001;
    }
    .fix{
        position: fixed;
        right: 40px;
        bottom: 90px;
        .fix-item{
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            background: #3053A3;
            border-radius: 4px;
            margin-bottom: 6px;
        }
    }
}
</style>
