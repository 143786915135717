<template>
    <el-select v-model="language" class="language-select" size="mini">
        <el-option v-for="item in languageList" :key="item.value" :label="item.label" :value="item.value"></el-option>
    </el-select>
</template>

<script>

import { setLanguage, getLanguage } from "@/lang/index.js";
import { languageList } from "./data.js";
export default {
    name: 'language-select',
    components: {

    },
    data() {
        return {
            lang: null,
            languageList: languageList,
        }
    },
    computed: {
        language: {
            set(val) {
                setLanguage(val);
                this.lang = getLanguage();
                location.reload();
            },
            get() {
                if (this.lang == null) this.lang = getLanguage();
                return this.lang;
            }
        }
    },
    watch: {

    }
}
</script>
<style lang="less" scoped>
.language-select {
    width: 80px;
    height: 28px;
    /deep/ .el-input {
        .el-input__inner {
            background-color: #cccccc;
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
            padding-left: 12px;
            padding-right: 18px;
            border: 0;
        }
        .el-input__suffix {
            .el-input__icon {
                font-size: 9px;
                width: 18px;
                color: #ffffff;
            }
        }
    }
    /deep/ .el-icon-arrow-up:before {
        content: "\e78f";
    }
}
</style>
