<template>
    <div :class="{'sign-up-qrcode': true, 'en': lang == 'en'}">
        <el-image class="qrcode-img" fit="contain" :src="signUpQrcode">
        </el-image>
        <a class="qrcode-text" :href="url" target="_blank">{{$t('common.signUpQrCode')}}</a>
    </div>
</template>

<script>
import signUpQrcode from "@/assets/img/sign-up-qrcode.png";
import { getLanguage } from "@/lang/index.js";
export default {
    name: 'layout2-sign-up-qrcode',
    components: {

    },
    data() {
      return {
        signUpQrcode,
        url: 'http://e.zbase.cn/c42c01',
        lang: getLanguage(),
      }
    },
    mounted(){
      
    },
    methods:{
      
    },
    computed: {

    },
    watch: {

    }
}
</script>
<style lang="less" scoped>
.sign-up-qrcode {
    position: fixed;
    width: 140px;
    // height: 167px;
    background: linear-gradient(180deg, #0054a5 0%, #3053A3 100%);
    border-radius: 6px;
    bottom: 160px;
    right: 30px;
    z-index:2001;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    .qrcode-img {
        width: 112px;
        height: 112px;
        border-radius: 0px;
        margin: 14px;
        margin-bottom: 8px;
    }
    .qrcode-text {
      display: block;
      text-decoration: none;
      cursor:pointer;
      width: 112px;
      margin: 0 14px;
      padding: 5px 0px;
      background: #FFAC1D;
      border-radius: 6px;
      margin-bottom: 8px;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      color: #5E1317;
      white-space: break-spaces;
    }
}
.en{
  &.sign-up-qrcode{
    width: 160px;
    .qrcode-img {
      width: 132px;
      height: 132px;
    }
    .qrcode-text{
      width: 132px;
      font-size: 12px;
    }
  }
}
</style>
