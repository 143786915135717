<template>
    <div class="qrcode" v-if="$store.state.qrcode.open && $store.state.qrcode.url">
        <el-image class="qrcode-img" fit="contain" :src="$store.state.qrcode.url">
        </el-image>
        <div class="qrcode-text">{{$t('common.qrCode')}}</div>
    </div>
</template>

<script>
export default {
    name: 'layout2-qrcode',
    components: {

    },
    data() {
        return {
            open: false,
            url: '',
        }
    },
    mounted(){
        this.$store.dispatch("qrcode/initQrCode");
    },
    methods:{
       
    },
    computed: {

    },
    watch: {

    }
}
</script>
<style lang="less" scoped>
.qrcode {
    position: fixed;
    width: 140px;
    // height: 167px;
    background: #ffffff;
    border-radius: 6px;
    top: 275px;
    right: 30px;
    z-index:1000;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    .qrcode-img {
        width: 120px;
        height: 120px;
        border-radius: 0px;
        margin: 10px;
        margin-bottom: 8px;
    }
    .qrcode-text {
        margin-bottom: 9px;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: #3053A3;
        white-space: break-spaces;
    }
}
</style>
