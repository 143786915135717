<template>
    <div class="header-main">
        <div class="header-top">
            <Block>
                <div class="start-end">
                    <div class="left">
                        <template v-if="!isLogin()">
                            <span>{{$t('header.welcome')}}</span>
                            <span class="button" @click="$router.push({path: '/login'})">{{$t('header.loginButton')}}</span>
                            <span class="red button" @click="$router.push({path: '/register'})">{{$t('header.registerButton')}}</span>
                        </template>
                        <template v-else>
                            <span>{{$t('header.loginWelcome')}}</span>
                            <span v-if="$store.state.user.userItem" class="company_name">{{$store.state.user.userItem.company_name}}</span>
                            <span v-if="$store.state.user.userItem" class="contact">{{$store.state.user.userItem.contact}}</span>
                            <span class="button" @click="logout">{{$t('header.logoutButton')}}</span>
                        </template>
                    </div>
                    <div class="right">
                        <img :src="languageIcon" />
                        <span class="language">{{$t('header.language')}}</span>
                        <language-select class="language-select"></language-select>
                    </div>
                </div>
            </Block>
        </div>
        <Block>
            <div class="header-content">
                <div class="left">
                    <img :src="logo" style="cursor:pointer;width: 130px;" @click="$router.push('/')" />
                </div>
                <div class="right">
                    <div :class="{menu: true, activate: checked == item.key, en: lang == 'en'}" v-for="(item, i) in menu" :key="i" @click="jump(item)">
                        <template v-if="item.key == 'cart'">
                            <!-- {{item.name}} <span style="color: #3053A3" v-if="isLogin()">{{$store.state.cart.num}}</span> -->
                            <el-badge :value="$store.state.cart.num" class="num" v-if="isLogin()">{{item.name}}</el-badge>
                            <div v-else>{{item.name}}</div>
                        </template>
                        <template v-else>
                            {{item.name}}
                        </template>
                        <div class="line"></div>
                    </div>
                </div>
            </div>
        </Block>
    </div>
</template>

<script>

import LanguageSelect from "@/components/language-select/index2.vue";
import languageIcon from "@/assets/img/language.png";
import logo from "@/assets/img/logo.png";
import Block from './layout2-block.vue';
import { isLogin } from "@/libs/tools.js";
import { getLanguage } from "@/lang/index.js";
export default {
    name: 'layout2-header',
    components: {
        LanguageSelect,
        Block,
    },
    props: {
        //默认选中的菜单key
        checked: {
            type: String,
            default: '',
        }
    },
    data() {
        return {
            lang: getLanguage(),
            languageIcon,
            logo,
            //菜单数据
            menu: [
                { key: 'online-exhibition', name: this.$t("header.menu1"), url: '/online-exhibition' },
                { key: 'introduction', name: this.$t("header.menu2"), url: '/introduction' },
                { key: 'scene', name: this.$t("header.menu3"), url: '/scene' },
                { key: 'skybird', name: this.$t("header.menu4"), url: 'http://www.toysaas.com/', type: 'url' },
                { key: 'cart', name: this.$t("header.menu5"), url: '/cart' },
                { key: 'inquiry', name: this.$t("header.menu6"), url: '/inquiry' },
            ]
        }
    },
    methods: {
        isLogin,
        logout() {
            this.$store.dispatch('user/logout').then(res => {
                // this.$router.push('/');
                window.location.reload();
            })
        },
        jump(item) {
            let url = item.url;
            if (item.type != undefined && item.type == 'url') {
                window.open(url);
            } else if (this.$route.path != url) {
                this.$router.push({ path: url })
            }
        }
    },
    computed: {

    },
    watch: {

    },
    mounted() {
        if (isLogin() && this.$store.state.user.userItem == null) {
            this.$store.dispatch('user/personal')
        }
    }
}
</script>
<style lang="less" scoped>
.header-main {
    width: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.header-top {
    height: 36px;
    font-size: 13px;
    font-weight: 400;
    color: #999999;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ededed;
    min-width: 100%;
    .left {
        display: flex;
        align-items: center;
        .button {
            cursor: pointer;
            margin-left: 24px;
        }
        .red {
            color: #3053A3;
        }
        .company_name {
            margin-left: 2px;
        }
        .contact {
            margin-left: 10px;
        }
    }
    .right {
        display: flex;
        align-items: center;
        .language {
            margin-left: 6px;
        }
        .language-select {
            margin-left: 10px;
        }
    }
}
.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px;
    .right {
        display: flex;
        .menu {
            margin-right: 70px;
            font-size: 16px;
            font-weight: bold;
            color: #333333;
            cursor: pointer;
            &:last-child {
                margin-right: 0;
            }
        }
        .menu:hover {
            color: #3053A3;
        }
        .en {
            margin-right: 37px;
        }
        .activate {
            color: #3053A3;
            position: relative;
            .line {
                width: 30px;
                height: 3px;
                background: #3053A3;
                border-radius: 100px 100px 0px 0px;
                position: absolute;
                bottom: -6px;
                left: 50%;
                transform: translate(-50%, 0);
            }
        }
    }
}
.start-end {
    display: flex;
    justify-content: space-between;
}
.num{
    /deep/ .el-badge__content{
        background-color: #3053A3;
    }
    /deep/ .el-badge__content.is-fixed{
        right: -3px;
    }
}
/deep/ .el-badge{
    vertical-align: baseline;
}
</style>
